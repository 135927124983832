import ThemeProvider from "@unlockre/components-library/dist/theme-provider";
import {OpenApiClientsProvider} from "@unlockre/open-api-client/dist/open-api-clients-context";
import {organizationsApiName} from "@unlockre/organizations-api-tools/dist";
import {PageAuth0Provider} from "@unlockre/page-tools/dist";
import type {PageWithLayoutAppProps} from "@unlockre/page-tools/dist";
import {AmplitudeClientProvider} from "@unlockre/utils-amplitude/dist";
import {SplitIoProvider} from "@unlockre/utils-split-io/dist";
import {SWRConfig} from "swr";

import Page from "@/components/page";
import {amplitudeClient} from "@/utils/amplitude";
import {dealRoomApiClient, dealRoomApiName} from "@/utils/deal-room-api";
import environmentVariables from "@/utils/environment/environment-variables";
import {estimateApiClient, estimateApiName} from "@/utils/estimate-api";
import {organizationsApiClient} from "@/utils/organizations-api/organizations-api-client";
import * as reactPdf from "@/utils/react-pdf";
import {splitIoFactory} from "@/utils/split-io/split-io-factory";

reactPdf.initialize();

const openApiClientRecord = {
  [dealRoomApiName]: dealRoomApiClient,
  [estimateApiName]: estimateApiClient,
  [organizationsApiName]: organizationsApiClient
};

/* eslint-disable react/jsx-max-depth */
const Application = (appProps: PageWithLayoutAppProps) => (
  <PageAuth0Provider
    audience={environmentVariables.AUTH0_AUDIENCE}
    clientId={environmentVariables.AUTH0_CLIENT_ID}
    domain={environmentVariables.AUTH0_DOMAIN}
  >
    <SplitIoProvider factory={splitIoFactory}>
      <AmplitudeClientProvider {...{amplitudeClient}}>
        <SWRConfig
          value={{
            fetcher: (resource, init) =>
              fetch(resource, init).then(res => res.json())
          }}
        >
          <OpenApiClientsProvider value={openApiClientRecord}>
            <ThemeProvider>
              <Page {...{appProps}} />
            </ThemeProvider>
          </OpenApiClientsProvider>
        </SWRConfig>
      </AmplitudeClientProvider>
    </SplitIoProvider>
  </PageAuth0Provider>
);
/* eslint-enable */

export default Application;
