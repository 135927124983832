import {Font} from "@react-pdf/renderer";

import MsMadiRegular from "./ms-madi-regular.ttf";

const initialize = () =>
  Font.register({
    family: "Ms Madi",
    fonts: [
      {
        src: MsMadiRegular
      }
    ]
  });

export default initialize;
